import { registerApplication, start, addErrorHandler } from "single-spa";
import {
  constructApplications,
  constructRoutes,
  constructLayoutEngine,
} from "single-spa-layout";
import microfrontendLayout from "./microfrontend-layout.html";

const routes = constructRoutes(microfrontendLayout);
const applications = constructApplications({
  routes,
  loadApp({ name }) {
    return System.import(name);
  },
});
const layoutEngine = constructLayoutEngine({ routes, applications });

applications.forEach(registerApplication);

addErrorHandler((err) => {
  console.error("Error de carga de la aplicación:", err);

  // Puedes mostrar un mensaje de error en el DOM o redirigir al usuario a una página de error.
  const errorMessage = document.createElement("div");
  errorMessage.className = "error-message";
  errorMessage.innerHTML = `
    <p>¡Ups! Ocurrió un error al cargar la aplicación.</p>
    <p>Por favor, inténtalo de nuevo más tarde.</p>
    <p>Si el error persiste, contacta con el administrador.</p>
  `;

  document.body.appendChild(errorMessage);

  // Puedes redirigir al usuario a una página de error si lo deseas.
  // window.location.href = '/error.html';
});

layoutEngine.activate();
start();
